<template>
  <div class="page">
    <div class="page-back">
      <div class="back-btn"
           @click="goBack">
        返回
      </div>
      <div class="title">
        车辆详情
      </div>
    </div>
    <div class="content-box section">
      <easy-card title="基本信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 车牌号码</template>
            <Numplate :type="basicInfo.plateColor">
              {{ basicInfo.plateNumber }}
            </Numplate>
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 绑定用户</template>
            {{ basicInfo.bindingUserName }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 车牌颜色</template>
            {{ plateNumberColor[(basicInfo.plateColor)] }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 品牌型号 </template>
            {{basicInfo.brandModel}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 车身颜色 </template>
            {{basicInfo.autoBodyColor}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 车辆类型 </template>
            {{basicInfo.autoType}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 车辆余额（元）</template>
            {{ (parseFloat(basicInfo.autoBalance)).toFixed(2) }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> ETC免密 </template>
            {{basicInfo.etcFreeSecret}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 注册时间</template>
            {{ basicInfo.registTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 绑定时间</template>
            {{ basicInfo.bindingTime }}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 认证时间 </template>
            {{basicInfo.certificationTime}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> </template>
          </el-descriptions-item> -->
        </el-descriptions>
      </easy-card>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item
            label="导出条数"
            label-width="120px"
            prop="exportSize"
        >
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport"
        >确认导出
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
// import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";
import {
  getVehicleInfo,

  getCouponInfo,
  getLeaseInfo,
  getParkingOrderInfo,
  getVehicleParkingRecords,
  getVehiclePatrolFeeInfo,
  getVehicleRechargeRecord,
  getVehicleTransaction,
  vehicleBindRecording
} from "@/api/vehicle";

export default {
  components: {
    // SuggestParking,
    Numplate
  },
  data() {
    return {
      id: "",
      isLoading: false,
      basicInfo: {}, //基本信息
      // insuranceInfo: [], //保单信息
      // maintainInfo: [], //保养信息
      // yearlyInspectionInfo:[], //年检信息
      longLeaseInfo: [], //长租信息
      bindingInfo: [],//绑定记录
      rechargeInfo: [], //充值查询
      tradeInfo: [], //交易查询
      inspectionPayInfo: [], //巡检缴费
      parkingOrderInfo: [], //停车订单
      couponInfo: [], //优惠券信息
      parkingRecordInfo: [], //停车记录
      query: {
        parkingLotId: "",
        driveInTimeStart: "",
        driveInTimeEnd: "",
      },
      driveInTime: [],
      total: 0,
      currentTab: "recharge",
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      plateNumberColor: {
        "01": "黄底黑字",
        "02": "蓝底白字",
        "03": "渐变绿色",
        "04": "黄绿渐变",
        "06": "黑底白字",
        "23": "白底黑字",
        "99": "其他",

        "黄色": "黄底黑字",
        "蓝色": "蓝底白字",
        "绿色": "渐变绿色",
        "黑色": "黑底白字",
        "白色": "白底黑字",
      },
    };
  },
  methods: {
    //返回车辆管理列表页
    goBack() {
      this.$router.back();
    },
    //查询基本信息
    getBasicInfo(id) {
      getVehicleInfo(id).then(res => {
        if (res.code === 30 && res.result) {
          this.basicInfo = res.returnObject;
        }
      })
    },
    //查询长租信息
    getLongLeaseInfo(id) {
      this.isLoading = true;
      getLeaseInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.longLeaseInfo = res.returnObject;
        }
      })
    },
    //查询车辆绑定记录
    getBingdingInfo(id) {
      this.isLoading = true;
      vehicleBindRecording(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.bindingInfo = res.returnObject;
        }
      })
    },
    //查询车辆充值
    getRechargeInfo(id) {
      this.isLoading = true;
      getVehicleRechargeRecord(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.rechargeInfo = res.returnObject;
        }
      })
    },
    //交易查询信息
    getTradeInfo(id) {
      this.isLoading = true;
      getVehicleTransaction(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.tradeInfo = res.returnObject;
        }
      })
    },
    //巡检缴费信息
    getInspectionPayInfo(id) {
      this.isLoading = true;
      getVehiclePatrolFeeInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.inspectionPayInfo = res.returnObject;
        }
      })
    },
    //查询停车订单信息
    getParkingOrderInfo(id) {
      this.isLoading = true;
      getParkingOrderInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.parkingOrderInfo = res.returnObject;
        }
      })
    },
    //查询优惠券信息
    getCouponInfo(id) {
      this.isLoading = true;
      getCouponInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.couponInfo = res.returnObject;
        }
      })
    },

    //查询停车记录
    goSearch() {
      this.isLoading = true;
      const vehicleId = this.id;
      const parkingLotId = this.query.parkingLotId;
      if (this.driveInTime.length > 0) {
        this.query.driveInTimeStart = this.driveInTime[0];
        this.query.driveInTimeEnd = this.driveInTime[1];
      }
      const driveInTimeStart = this.query.driveInTimeStart;
      const driveInTimeEnd = this.query.driveInTimeEnd;
      getVehicleParkingRecords(parkingLotId, driveInTimeStart, driveInTimeEnd, vehicleId).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.parkingRecordInfo = res.returnObject;
        }
      })
    },
    //重置
    resetForm() {
      this.$refs.query.resetFields();
      this.driveInTime = [];
      this.goSearch();
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      });
      // const params = this.paramFormat(this.query);
      // if(this.searchDate.length > 0) {
      //   params["startDate"] = this.searchDate[0];
      //   params["endDate"] = this.searchDate[1];
      // }
      // params["isExport"] = "1";
      // params["exportSize"] = this.form.exportSize;
      const res = getVehicleParkingRecords();
      if (res && res.code === 30 && res.result) {
        loading.close();
        this.exportDialogFormVisible = false;
      }
    }
  },
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    this.getBasicInfo(id);
    // this.getLongLeaseInfo(id);
    // this.getBingdingInfo(id);
    // this.getRechargeInfo(id);
    // this.getTradeInfo(id);
    // this.getInspectionPayInfo(id);
    // this.getParkingOrderInfo(id);
    // this.getCouponInfo(id);
    // this.goSearch();
  },
};
</script>


<style lang="less" scoped>
.tabs {
  margin-top: 20px;
}

.footer {
  margin-top: -30px;
}
</style>
