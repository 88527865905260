import request from '@/utils/request'

//车辆管理列表查询
export function getVehicleList(pageNo, pageSize, plateNumber) {
    return request.post('/user/vehicleManagerPage?pageNo=' + pageNo + "&pageSize=" + pageSize+"&plateNumber="+plateNumber)
}

//获取车辆详情基本信息
export function getVehicleInfo(id) {
    return request.post('/user/vehicleManagerInfo?id=' + id)
}

//获取长租信息
export function getLeaseInfo(id) {
    return request.get('/vehicleController/getLeaseInfo?id=' + id)
}

//获取车辆绑定记录
export function vehicleBindRecording(id) {
    return request.get('/vehicleController/vehicleBindRecording?id=' + id)
}

//获取车辆充值查询
export function getVehicleRechargeRecord(id) {
    return request.get('/vehicleController/getVehicleRechargeRecord?id=' + id)
}

//获取车辆交易查询
export function getVehicleTransaction(id) {
    return request.get('/vehicleController/getVehicleTransaction?id=' + id)
}

//获取车辆巡检缴费信息
export function getVehiclePatrolFeeInfo(id) {
    return request.get('/vehicleController/getVehiclePatrolFeeInfo?id=' + id)
}

//获取停车订单信息
export function getParkingOrderInfo(id) {
    return request.get('/vehicleController/getParkingOrderInfo?id=' + id)
}

//获取优惠券信息
export function getCouponInfo(id) {
    return request.get('/vehicleController/getCouponInfo?id=' + id)
}

//获取车辆停车记录（需支持导出）
export function getVehicleParkingRecords(parkingLotId, driveInTimeStart, driveInTimeEnd, vehicleId) {
    return request.get('/vehicleController/getVehicleParkingRecords?parkingLotId=' + parkingLotId + "&driveInTimeStart=" + driveInTimeStart + "&driveInTimeEnd=" + driveInTimeEnd + "&vehicleId=" + vehicleId)
}
